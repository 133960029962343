import { Link } from "gatsby";
import React, { useState } from "react";

import PropTypes from "prop-types";

function Header({lang}) {
  const [isExpanded, toggleExpansion] = useState(false);
  
  return (
    <header className="bg-blue-600 shadow-sm">
      <div className="flex flex-wrap text-white items-center justify-between mx-auto px-4 py-4 md:px-16 md:py-8">
        <Link to={lang === "en" ? `/` : `/de/`}>
          <h1 className="flex items-center no-underline mb-0">
            <SendenIcon></SendenIcon>
            <span className="ml-2 text-white text-lg leading-none font-bold tracking-tight">
              Paperplane <br /> Pages
            </span>
          </h1>
        </Link>

        <button
          className="flex items-center block px-3 py-2 border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex md:items-center w-full md:w-auto`}
        >

          {[
            {
              route: lang === "en" ? `/` : `/de/`,
              title: lang === "en" ? `Home` : `Home`,
            },
            {
              route: lang === "en" ? `/about` : `/de/about`,
              title: lang === "en" ? `About` : `Über Uns`,
            },
            {
              route: lang === "en" ? `/contact` : `/de/contact`,
              title: lang === "en" ? `Contact` : `Kontakt`,
            },
          ].map((link) => (
            <Link
              className="block mt-4 no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
          { lang === "en" ?
            <Link className="block mt-4 no-underline md:inline-block md:mt-0 md:ml-6" to="/de/">
              DE
            </Link> :
            <Link className="block mt-4 no-underline md:inline-block md:mt-0 md:ml-6" to="/">
              EN
            </Link>
          }
        </nav>
      </div>
    </header>
  );
}

Header.propTypes = { lang: PropTypes.string.isRequired }

const SendenIcon = () => {
  return (
<svg viewBox="0 0 511.92 511.92" className="w-8 h-8 fill-current" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" >
  <g>
    <path d="M505.77,46.551c-4.773-3.978-11.344-5.013-17.109-2.697L56.482,218.293c-4.371,1.767-6.482,6.744-4.715,11.115
      s6.744,6.482,11.115,4.715l412.86-166.588l-204.8,241.545c-3.047,3.596-2.602,8.981,0.994,12.028
      c3.596,3.047,8.981,2.602,12.028-0.994L491.861,74.899l-27.785,139.469l-27.989,187.025c-0.384,2.617-1.96,4.909-4.267,6.204
      c-2.212,1.235-4.877,1.358-7.194,0.333l-116.369-45.756l-55.842-23.381c-4.345-1.823-9.346,0.22-11.17,4.565
      c-0.186,0.585-0.307,1.189-0.358,1.801h-0.205l-27.725,107.059l-39.782-138.965l207.36-154.453
      c2.65-1.741,4.117-4.807,3.808-7.963c-0.309-3.156-2.342-5.88-5.279-7.074c-2.937-1.194-6.295-0.661-8.718,1.384L161.92,300.324
      L11.682,239.507c-4.36-1.729-9.298,0.384-11.057,4.732c-1.759,4.348,0.321,9.3,4.657,11.089l151.04,61.116l40.277,140.612
      c2.097,7.263,8.756,12.254,16.316,12.228h0.196c4.121-0.074,8.07-1.663,11.093-4.463c0.298-0.179,0.583-0.379,0.853-0.597
      l82.483-84.343l110.592,43.853c7.107,3.019,15.214,2.565,21.939-1.229c6.93-3.844,11.682-10.689,12.86-18.526l27.913-186.675
      l30.72-154.121C512.842,56.995,510.616,50.605,505.77,46.551z M236.877,427.786l18.014-69.436l28.211,11.844l7.27,2.884
      L236.877,427.786z"/>
    </g>	
</svg>
  );
}

export default Header;
