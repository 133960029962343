import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby";

const Footer = ({ lang }) => {
    return (
        <footer className="bg-blue-700">
            <nav className="flex justify-start px-8 py-4 md:px-16 mx-auto text-sm text-blue-300">
            <Link to="/imprint">{ lang === "en" ? "Imprint" : "Impressum"}</Link>
            <Link className="ml-3" to="/data-privacy">{ lang === "en" ? "Data Privacy" : "Datenschutz"}</Link>
            </nav>
        </footer>
    )
}

Footer.propTypes = {
    lang: PropTypes.string.isRequired
}

export default Footer
