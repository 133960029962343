import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";
import CookieBannerCookieHub from "./cookie-banner-cookie-hub";

function Layout({ children, lang }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <CookieBannerCookieHub googleTrackingId="UA-165335494-1" cookieHubId="93c9fd41"/>
      <Header lang={lang} />

      <main>
        {children}
      </main>

      <Footer lang={lang} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string.isRequired,
};

export default Layout;
